import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const support: Routes = [
  {
    path: 'support', children: [{
      path: '',
      loadComponent: () =>
        import('./support.component').then(
          (m) => m.SupportComponent
        ),
      title: 'LOONE - Support'
    },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(support)],
  exports: [RouterModule],
})
export class supportRoutingModule {
  static routes = support;
}