import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const content1: Routes = [
  { path: '', children: [] },
];
@NgModule({
  imports: [
    RouterModule.forRoot(content1)
  ],
  exports: [RouterModule],
})
export class CRMRoutingModule {}
