import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revoked-share-page',
  templateUrl: './revoked-share-page.component.html',
  styleUrls: ['./revoked-share-page.component.scss']
})
export class RevokedSharePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
