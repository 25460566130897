import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';  
import { FormsModule } from '@angular/forms'; 
import { environment } from '../../environments/environment';
import { UserService } from '../shared/services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../shared/models/user';
import { Enterprise, EnterprisePreferences } from '../shared/models/enterprise';
import { InseeService } from '../shared/services/insee/insee.service';
import { PlaceAutocompleteComponent, PlaceSearchResult }from '../place-autocomplete/place-autocomplete.component';
import { EnterpriseService } from '../shared/services/enterprises/enterprises.service';
import confetti from 'canvas-confetti';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import 'hammerjs';

@Component({
  selector: 'app-onboarding',
  standalone: true,
  imports: [CommonModule, FormsModule, PlaceAutocompleteComponent, ImageCropperComponent],  
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']  
})
export class OnboardingComponent implements OnInit {
  @ViewChild('cropperModal') cropperModal!: TemplateRef<any>;
  private modalRef!: NgbModalRef;
  currentStep: number = 0;
  totalSteps: number = 3;  // Adjusted to match the form steps

  imageChangedEvent: any = '';
  croppedImage: string = '';

  // Form data structure (Profile & Company data)
  profileFormData: Partial<User> = {
    firstName: '',
    lastName: '',
    profilePhoto: '',
    phone: '',
    address: '',
    postcode: '',
    city: '',
    country: '',
    termsConditionsAccepted: null ,
    termsCommercialsAccepted: null // Moved the termsAccepted here
  };

  companyFormData: any = {
    companyName: '',
    siret: '',
    headOffice: '',
    nafCode: '',
    tvaNumber: '',
    vatEligible: false,
    website: '',
    logo: ''
  };

  addressProfil:any = {
    street: '',
    city: '',
    postalCode: '',
    country: '',
    complete:'',
  };;

  showVideo: boolean = true; // Affiche la vidéo au démarrage

  @ViewChild('introVideo') introVideo: any; // Référence à la vidéo dans le template

  user: User | undefined | null = null;

  constructor(private http: HttpClient, private router: Router, private userService: UserService,
     private toastr: ToastrService, private inseeService: InseeService, private enterpriseService: EnterpriseService, private modalService: NgbModal) {}

  ngOnInit(){
    this.userService.getCurrentUser().subscribe(
      user => {
        this.user = user;
      },
      error => {
        console.error('Erreur lors du chargement des préférences utilisateur:', error);
        this.toastr.error('Erreur lors du chargement des données de l\'utilisateur.');
      }
    );
  }

  errorMessage: string | null = null;
  maxFileSize = 2 * 1024 * 1024; // Limite de taille du fichier (2 Mo)

  // Simule un clic sur l'input de fichier caché
  onLogoClick(inputId: string): void {
    document.getElementById(inputId)?.click();
  }

  // Gère la sélection de fichier pour profileFormData ou companyFormData
  onFileSelected(event: Event, formType: 'profile' | 'company'): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      const validTypes = ['image/jpeg', 'image/png'];

      // Vérification du type de fichier
      if (!validTypes.includes(file.type)) {
        this.errorMessage = 'Seuls les fichiers JPG et PNG sont autorisés.';
        if (formType === 'profile') {
          this.profileFormData.profilePhoto = '';
        } else {
          this.companyFormData.logo = '';
        }
        fileInput.value = ''; // Réinitialiser le champ de fichier
        return;
      }

      // Vérification de la taille du fichier
      if (file.size > this.maxFileSize) {
        this.errorMessage = 'La taille du fichier ne doit pas dépasser 2 Mo.';
        if (formType === 'profile') {
          this.profileFormData.profilePhoto = '';
        } else {
          this.companyFormData.logo = '';
        }
        fileInput.value = ''; // Réinitialiser le champ de fichier
        return;
      }

      // Si tout est valide, chargez l'image
      this.errorMessage = null;
      const reader = new FileReader();
      reader.onload = e => {
        if (formType === 'profile') {
          this.profileFormData.profilePhoto = reader.result as string;
        } else {
          this.companyFormData.logo = reader.result as string;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onFileChange(event: any, formType: 'profile' | 'company'): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      const validTypes = ['image/jpeg', 'image/png'];
  
      if (!validTypes.includes(file.type)) {
        this.errorMessage = 'Seuls les fichiers JPG et PNG sont autorisés.';
        return;
      }
  
      this.imageChangedEvent = event;
      this.modalRef = this.modalService.open(this.cropperModal, { backdrop: 'static', keyboard: false });
    }
  }
  
  imageCropped(event: any, formType: 'profile' | 'company'): void {    
    if (event && event.blob) {
      const file = event.blob;
  
      // Convertir le Blob en Base64 pour l'aperçu si nécessaire
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result as string;
  
        // Assigner le résultat au bon formType
        if (formType === 'profile') {
          this.profileFormData.profilePhoto = base64data; // Stocker l'image Base64 pour l'affichage
        } else {
          this.companyFormData.logo = base64data; // Stocker l'image Base64 pour l'affichage
        }
        
        // Enregistrer le Blob pour l'envoi au backend si nécessaire
        if (formType === 'company') {
          this.companyFormData.logoBlob = file; // Assigner le Blob directement pour l'enregistrement
        }
      };
    } else {
      console.error('Erreur lors du recadrage de l\'image. Aucun blob trouvé.', event);
    }
  }  
  
  resetLogo(formType: 'profile' | 'company'): void {
    // Réinitialise la variable dans le modèle
    if (formType === 'profile') {
      this.profileFormData.profilePhoto = ''; // Réinitialise la photo de profil
      const inputElement = document.getElementById('profile-logo-input') as HTMLInputElement;
      if (inputElement) {
        inputElement.value = ''; // Réinitialise l'input file pour pouvoir re-sélectionner la même image
      }
    } else {
      this.companyFormData.logo = ''; // Réinitialise le logo de l'entreprise
      const inputElement = document.getElementById('company-logo-input') as HTMLInputElement;
      if (inputElement) {
        inputElement.value = ''; // Réinitialise l'input file pour pouvoir re-sélectionner la même image
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showVideo = false;
    }, 10000); // Durée de la vidéo (7 secondes)
  }

  onVideoEnd() {
    this.showVideo = false;
  }

  // Valide uniquement pour l'étape 0
  isFormProfileEntValid(): boolean {
    if (this.currentStep === 0) {
      // Vérifier si les champs obligatoires sont remplis
      const isLastNameValid = (this.profileFormData.lastName?.trim().length ?? 0) > 0;
  
      // Vérification que l'adresse est complète et chaque champ de l'adresse est non vide
      const isStreetValid = this.addressProfil.street?.trim().length > 0;
      const isCityValid = this.addressProfil.city?.trim().length > 0;
      const isPostalCodeValid = this.addressProfil.postalCode?.trim().length > 0;
      const isCountryValid = this.addressProfil.country?.trim().length > 0;
      const isAddressValid = isStreetValid && isCityValid && isPostalCodeValid && isCountryValid;
  
      // Vérifier que le numéro de téléphone est valide et contient au moins 10 chiffres
      const isPhoneValid = (this.profileFormData.phone?.trim().length ?? 0) >= 10;
  
      // Convertir les valeurs en booléens (true si elles existent, false sinon)
      const areTermsAccepted = !!this.profileFormData.termsConditionsAccepted && !!this.profileFormData.termsCommercialsAccepted;
  
      // Gestion des messages d'erreur
      if ((!isLastNameValid || !isAddressValid || !isPhoneValid) && !areTermsAccepted) {
        this.errorMessage = 'Veuillez remplir tous les champs obligatoires et accepter les conditions.';
      } else if (!isLastNameValid || !isAddressValid || !isPhoneValid) {
        // Message spécifique pour l'adresse ou le téléphone
        if (!isLastNameValid) {
          this.errorMessage = 'Veuillez renseigner votre nom.';
        } else if (!isAddressValid) {
          this.errorMessage = 'Veuillez renseigner une adresse valide.';
        } else if (!isPhoneValid) {
          this.errorMessage = 'Veuillez entrer un numéro de téléphone valide (au moins 10 chiffres).';
        }
      } else if (!areTermsAccepted) {
        this.errorMessage = 'Veuillez accepter les conditions d\'utilisation.';
      } else {
        this.errorMessage = null; // Tout est valide
      }
  
      // Retourne true uniquement si tous les champs sont valides et les conditions sont acceptées
      return isLastNameValid && isAddressValid && isPhoneValid && areTermsAccepted;
    }
  
    if (this.currentStep === 1) {
      return this.isCompanyFormValid();
    }
  
    // Réinitialiser le message d'erreur si tout est valide
    this.errorMessage = null;
    return true;
  }
  

  // Valide le formulaire de l'étape 1

isCompanyFormValid(): boolean {
  const isCompanyNameValid = this.companyFormData.companyName.trim().length > 0;
  const isSiretValid = this.companyFormData.siret.trim().length > 0;
  const isHeadOfficeValid = this.companyFormData.headOffice.trim().length > 0;

  // Vérifier si un ou plusieurs champs sont manquants
  if (!isCompanyNameValid || !isSiretValid || !isHeadOfficeValid) {
    this.errorMessage = 'Veuillez remplir les champs obligatoires. *';
    return false;
  }

  // Si tout est valide
  this.errorMessage = null;
  return true;
}





  // Validates the form before going to the next step
  isFormValid(): boolean {
    if (this.currentStep === 2) { // Last step (accept terms)
      return true;
    }
    return true; // Add actual validations as needed
  }

  // Submits the form when onboarding is complete
  completeOnboarding() {
    const url = `${environment.apiUrl}/auth/completeOnboarding`;
    const formData = {
      profile: this.profileFormData,
      company: this.companyFormData
    };
    this.http.post(url, formData).subscribe(() => {
      this.router.navigate(['/dashboard']); // Adjust to the route after onboarding
    }, error => {
      console.error('Error during onboarding:', error);
    });
  }

  isBlob(obj: any): obj is Blob {
    return obj instanceof Blob;
  }

  // Fonction pour convertir base64 en Blob
  base64ToBlob(base64: string, mimeType: string): Blob {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  }
  



  newEnterprise: Enterprise = {
    _id: '',
    denomination: '',
    SIRET: '',
    SIREN: '',
    headOffice: '',
    NAFCode: '',
    TVA: '',
    category: '',
    website: '',
    owner: '',
    creationDate: '',
    legalForm: '',
    isVatEligible: false,
    logoSrc: null,
    preferences: this.getDefaultEnterprisePreferences()
  };

  siretError: string | null = null;

  private getDefaultEnterprisePreferences(): EnterprisePreferences {
    return {
      defaultFacturationType: 'Complet',
      showDeliveryAddress: false,
      showCoordBancaires: false,
      showIntituleDoc: false,
      showConditionsPaiement: false,
      showChampLibre: false,
      invoiceNumbering: {
        isCustom: false, 
        numberingModel: 'AAAA-MM-JJ-XXXXXX', 
        prefix: '',
        startingNumber: 1,
        currentNumber: 1,
      },
      quoteNumbering: {
        isCustom: false,
        numberingModel: 'AAAA-MM-JJ-XXXXXX', 
        prefix: '',
        startingNumber: 1,
        currentNumber: 1,
      },
      dateLastInvoice: '',
      dateLastQuote: ''
    };
  }  

  // Méthode pour valider et charger les infos entreprise
  validateAndLoadEnterpriseInfo(event: Event) {
    const input = event.target as HTMLInputElement; // Caster l'event.target en HTMLInputElement
    const siret = input.value; // Obtenez la valeur saisie par l'utilisateur

    const siretRegex = /^[0-9]{9}$|^[0-9]{14}$/; // Accepte 9 ou 14 chiffres
    
    if (!siretRegex.test(siret)) {
      this.siretError = 'Le numéro doit contenir 9 ou 14 chiffres.';
    } else {
      this.siretError = null; // Aucune erreur si la validation réussit

      // Appeler la méthode pour charger les infos entreprise
      this.loadEnterpriseInfo(siret);
    }
  }

  loadEnterpriseInfo(siret: string): void {
    this.inseeService.getEnterpriseInfo(siret).subscribe(
      (data: any) => {
        if (data && data.etablissement) {
          const etablissement = data.etablissement;
  
          // Fonction pour remplacer [ND], null ou undefined par une chaîne vide
          const replaceND = (value: string | null | undefined): string => {
            return value === '[ND]' || value == null ? '' : value;
          };
  
          this.newEnterprise = {
            ...this.newEnterprise,
            denomination: replaceND(etablissement.uniteLegale?.denominationUniteLegale || etablissement.uniteLegale?.nomUniteLegale),
            SIRET: replaceND(etablissement.siret),
            SIREN: replaceND(etablissement.uniteLegale?.siren),
            // Formatage de l'adresse comme une adresse Google
            headOffice: [
              replaceND(etablissement.adresseEtablissement.numeroVoieEtablissement),  // Numéro de voie
              replaceND(etablissement.adresseEtablissement.typeVoieEtablissement),    // Type de voie (rue, avenue, etc.)
              replaceND(etablissement.adresseEtablissement.libelleVoieEtablissement), // Nom de la voie
              replaceND(etablissement.adresseEtablissement.codePostalEtablissement),  // Code postal
              replaceND(etablissement.adresseEtablissement.libelleCommuneEtablissement) // Ville
            ]
            .filter(part => part?.trim())  // Filtre les parties vides ou contenant uniquement des espaces
            .map(part => part.trim())      // Supprime les espaces inutiles autour de chaque partie
            .join(', '),  
            NAFCode: replaceND(etablissement.uniteLegale?.activitePrincipaleUniteLegale),
            TVA: etablissement.uniteLegale?.caractereEmployeurUniteLegale ? this.calculateTVA(etablissement.uniteLegale.siren) : '',
            legalForm: replaceND(etablissement.uniteLegale?.categorieJuridiqueUniteLegale),
            category: replaceND(etablissement.uniteLegale?.categorieEntreprise)
          };

          this.companyFormData.companyName = this.newEnterprise.denomination.trim(); // Supprime les espaces avant d'assigner
          this.companyFormData.nafCode = this.newEnterprise.NAFCode.trim(); // Supprime les espaces avant d'assigner
          this.companyFormData.tvaNumber = this.newEnterprise.TVA.trim(); // Supprime les espaces avant d'assigner

            const addressParts = [
              replaceND(etablissement.adresseEtablissement.numeroVoieEtablissement),   // Numéro de voie
              this.capitalizeFirstLetter(replaceND(etablissement.adresseEtablissement.typeVoieEtablissement)),     // Type de voie (rue, avenue, etc.)
              this.capitalizeFirstLetter(replaceND(etablissement.adresseEtablissement.libelleVoieEtablissement)),  // Nom de la voie
              replaceND(etablissement.adresseEtablissement.codePostalEtablissement),   // Code postal
              this.capitalizeFirstLetter(replaceND(etablissement.adresseEtablissement.libelleCommuneEtablissement)), // Commune
              this.capitalizeFirstLetter(replaceND(etablissement.adresseEtablissement.country)),
            ];
          
            // Filtrer les parties vides et joindre les parties avec des virgules
            this.companyFormData.headOffice = addressParts
              .filter(part => part?.trim())     // Retire les parties nulles ou vides
              .map(part => part!.trim())        // Supprime les espaces superflus autour de chaque partie
              .join(', ');                      // Joint les parties avec des virgules
          
          
        } else {
          this.siretError = 'Données d\'entreprise non trouvées.';
        }
      },
      (error: any) => {
        console.error('Erreur lors du chargement des informations de l\'entreprise:', error);
      }
    );
  }  
    
  calculateTVA(sirenOrSiret: string): string {
    if (sirenOrSiret.length === 14) {
      sirenOrSiret = sirenOrSiret.substring(0, 9);
    }
    const numericValue = parseInt(sirenOrSiret, 10);
    const tva = (12 + (3 * (numericValue % 97))) % 97;
    return `FR${tva < 10 ? '0' + tva : tva}${sirenOrSiret}`;
  }
  

  onPlaceChanged(place: PlaceSearchResult, formType: 'profile' | 'company' ): void {
    if (place) {
      const fullAddress = [
        place.address,
        place.postcode, 
        place.city,
        place.country 
      ].filter(part => part).join(', '); 
      if(formType == 'company'){
          this.companyFormData.headOffice = fullAddress;
      }
      else{
        const addressData = {
          street: this.cleanAddressString(place.address),
          postalCode: place.postcode || '',
          city: place.city || '',
          country: place.country || ''
        };
        this.addressProfil = addressData;
        this.addressProfil.complete = fullAddress;
      }
    }
    
  }


  cleanAddressString(address: string): string {
    if (address) {
      const addressParts = address.split(',');
      return addressParts.length > 1 ? addressParts[0].trim() : address;
    }
    return address;
  }

  isLoading = false; // Variable pour contrôler l'affichage du spinner
  nextStep(): void {
    if (this.isFormProfileEntValid()) {
      this.isLoading = true; // Activer le spinner
      if (this.currentStep < this.totalSteps - 2) {
        this.currentStep++;
        this.isLoading = false; // Désactiver le spinner après avoir avancé d'une étape
      } else {
        this.updateUser();
      }
    }
  }

  

  // Fonction pour mettre en majuscule la première lettre et le reste en minuscule
  capitalizeFirstLetter(value: string | null | undefined): string {
    if (!value) {
      return ''; // Retourne une chaîne vide si la valeur est null ou undefined
    }
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
  
  updateUser() {
    this.isLoading = true;
    // Récupère les autres données du formulaire
    const updateData = {
      ...this.profileFormData,
      // Mettre en majuscule la première lettre du prénom et du nom, avec vérification de null/undefined
      firstName: this.capitalizeFirstLetter(this.profileFormData.firstName?.trim()),
      lastName: this.capitalizeFirstLetter(this.profileFormData.lastName?.trim()),
      // Conversion des booleans en dates si les conditions sont acceptées
      termsConditionsAccepted: this.profileFormData.termsConditionsAccepted ? new Date() : null,
      termsCommercialsAccepted: this.profileFormData.termsCommercialsAccepted ? new Date() : null,
      // Ajout de l'adresse
      address: this.addressProfil.street,
      postcode: this.addressProfil.postalCode,
      city: this.addressProfil.city,
      country: this.addressProfil.country
    };

    if (this.profileFormData.profilePhoto) {
      let file: Blob;
  
      // Vérification si profilePhoto est une chaîne base64
      if (typeof this.profileFormData.profilePhoto === 'string' && this.profileFormData.profilePhoto.startsWith('data:image')) {
        const mimeType = this.profileFormData.profilePhoto.match(/data:(.*);base64/)![1]; // Extraire le type MIME
        file = this.base64ToBlob(this.profileFormData.profilePhoto, mimeType);
      } else if (this.isBlob(this.profileFormData.profilePhoto)) {
        // Vérifie explicitement si c'est un Blob
        file = this.profileFormData.profilePhoto;
      } else {
        this.isLoading = false;
        console.error('Type de photo non pris en charge');
        return; // Sortir si le type est incorrect
      }
  
      const formData = new FormData();
      
      // Ajoute la photo au FormData avec un nom correct
      formData.append('profilePhoto', file, 'profile-photo.jpg'); // Utilise 'file' ici et non profilePhoto directement
      
      // Appel pour uploader la photo
      this.userService.uploadProfilePhoto(formData).subscribe(
        response => {  
          // Une fois la photo uploadée, appeler l'API pour mettre à jour les autres données utilisateur
          this.updateUserData(updateData);
        },
        error => {
          this.isLoading = false;
          console.error('Erreur lors du téléchargement de la photo de profil', error);
        }
      );
    } else {
      // Si pas de photo, appeler directement l'API pour les données textuelles
      this.updateUserData(updateData);
    }
  }

  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.charCode ? event.charCode : event.keyCode;
    
    // Vérifie si le caractère n'est pas un chiffre (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  
  
  updateUserData(updateData: any) {
    // Appeler le service API pour mettre à jour les données utilisateur
    this.userService.updateCurrentUser(updateData).subscribe(
      updatedUser => {  
        // Une fois l'utilisateur mis à jour, créer l'entreprise
        this.saveEnterprise();
      },
      error => {
        this.isLoading = false;
        console.error('Erreur lors de la mise à jour du profil', error);
      }
    );
  }
  
  saveEnterprise(): void {
    // Préparer les données à envoyer à l'API en incluant toutes les propriétés du modèle 'Enterprise'
    const newEnterprise: Enterprise = {
      _id: '', // Assigner un ID vide ou généré si nécessaire
      denomination: this.companyFormData.companyName || '', // Nom de l'entreprise
      SIRET: this.companyFormData.siret || '', // SIRET
      SIREN: '', // Assigner un SIREN vide si non fourni par le formulaire
      headOffice: this.companyFormData.headOffice || '', // Siège social
      NAFCode: this.companyFormData.nafCode || '', // Code NAF
      TVA: this.companyFormData.tvaNumber || '', // Numéro de TVA
      category: '', // Assigner une catégorie vide ou calculée si nécessaire
      website: this.companyFormData.website || '', // Site web
      owner: '', // Propriétaire (peut être rempli avec l'ID de l'utilisateur connecté par exemple)
      creationDate: new Date().toISOString(), // Date de création (remplie automatiquement avec la date actuelle)
      legalForm: '', // Forme juridique (peut être vide ou calculée si nécessaire)
      isVatEligible: this.companyFormData.vatEligible || false, // Éligibilité à la TVA
      logoSrc: this.companyFormData.logo || null, // Logo de l'entreprise
      preferences: this.getDefaultEnterprisePreferences()
    };
  
    // Appel de l'API pour créer l'entreprise
    this.enterpriseService.createEnterprise(newEnterprise).subscribe(
      response => {
        this.isLoading = false;
        this.currentStep++;
        setTimeout(() => {
          this.triggerConfetti();
        }, 800); // Délai de 1000 millisecondes (1 seconde)

      },
      error => {
        this.isLoading = false;
        console.error('Erreur lors de la création de l\'entreprise', error);
      }
    );
  }

  redirect(){
    this.completeOnboarding();
  }


  triggerConfetti(): void {
    confetti({
      particleCount: 200,
      spread: 160,
      origin: { y: 0.6 }
    });
  }

  

}
