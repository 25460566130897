import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { invoiceRoutingModule } from './facturation.routes';

export const admin: Routes = [
 {path:'crm',children:[
{
  path: 'contacts',
  loadComponent: () =>
    import('../components/contacts/contacts.component').then(
      (m) => m.ContactsComponent
    ),
    title: 'LOONE - Contacts'
},
{
  path: 'contacts/:id/contact',
  loadComponent: () =>
    import('../components/contacts/contact-detail/contact-detail.component').then(
      (m) => m.ContactDetailComponent
    ),
  title: 'LOONE - Contact Details'
},
{
  path: 'contacts/create',
  loadComponent: () =>
    import('../components/contacts/create-update-contact/create-update-contact.component').then(
      (m) => m.CreateUpdateContactComponent
    ),
  title: 'LOONE - Create Contact'
},
{
  path: 'profile/:id/enterprise',
  loadComponent: () =>
    import('../../user.profile/enterprise/enterprise-detail/enterprise-detail.component').then(
      (m) => m.EnterpriseDetailComponent
    ),
  title: 'LOONE - Entreprise'
},
{
  path: 'contracts/create',
  loadComponent: () =>
    import('../components/contracts/create-contract/create-contract.component').then(
      (m) => m.CreateContractComponent
    ),
  title: 'LOONE - Create Contract'
},
{
  path: 'contracts/:id',
  loadComponent: () =>
    import('../components/contracts/contract-detail/contract-detail.component').then(
      (m) => m.ContractDetailComponent
    ),
  title: 'LOONE - Contract Details'
},
{
  path: 'opportunities',
  loadComponent: () =>
    import('../components/opportunities/opportunity.component').then(
      (m) => m.OpportunitiesComponent
    ),
    title: 'LOONE - Opportunités'
},
{
  path: 'opportunities/:id/opportunity',
  loadComponent: () =>
    import('../components/opportunities/opportunity-detail/opportunity-detail.component').then(
      (m) => m.OpportunityDetailComponent
    ),
  title: 'LOONE - Opportunités'
},
{
  path: 'products',
  loadComponent: () =>
    import('../components/product/product.component').then(
      (m) => m.ProductsComponent
    ),
    title: 'LOONE - Produits & Services'
},
{
  path: '', // Quand la route crm est accédée directement
  redirectTo: '/dashboard', // Redirection vers /dashboard
  pathMatch: 'full' // Assurez-vous que c'est une correspondance exacte
},
{ path: '', children: [
 ...invoiceRoutingModule.routes,
]}
// {
//   path: 'activites',
//   loadComponent: () =>
//     import('../../../../activity/activity.component').then(
//       (m) => m.ActivitiesComponent
//     ),
//     title: 'LOONE - Activités'
// },

]}
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class crmRouting2Module {
  static routes = admin;
}