import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const admin: Routes = [
 {path:'',children:[ {
  path: 'agenda',
  loadComponent: () =>
    import('../components/agenda/agenda.component').then((m) => m.AgendaComponent),
    title: 'LOONE - Agenda'
  },
]}
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class appsRoutingModule {
  static routes = admin;
}