import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const search: Routes = [
  {
    path: 'search', children: [{
      path: '',
      loadComponent: () =>
        import('./search-results/search-results.component').then(
          (m) => m.SearchResultsComponent
        ),
      title: 'LOONE - Recherche'
    },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(search)],
  exports: [RouterModule],
})
export class searchRoutingModule {
  static routes = search;
}