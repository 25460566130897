<div class="video-container" *ngIf="showVideo">
  <video #introVideo autoplay muted playsinline class="full-screen-video" (ended)="onVideoEnd()">
    <source src="./assets/images/media/intro.mp4" type="video/mp4">
    Votre navigateur ne supporte pas la vidéo.
  </video>
</div>

<div class="container py-5" *ngIf="!showVideo">
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8">
      <div class="p-4">
        <span *ngIf="currentStep == 0" class="text-muted fs-11 d-block">1 SUR 3</span>  
        <span *ngIf="currentStep == 0">Profil</span>   
        <span *ngIf="currentStep == 1" class="text-muted fs-11 d-block">2 SUR 3</span>  
        <span *ngIf="currentStep === 1">Entreprise</span>  
        <span *ngIf="currentStep == 2" class="text-muted fs-11 d-block">3 SUR 3</span>  
        <span *ngIf="currentStep === 2">Terminé</span>     
        <div class="step-indicator">
          <div class="step me-1" [class.active]="currentStep >= 0"></div>
          <div class="step me-1" [class.active]="currentStep >= 1"></div>
          <div class="step" [class.active]="currentStep >= 2"></div>
        </div>
        

        <!-- Step 1: Profile Information -->
        <div *ngIf="currentStep === 0">
          <h3 class="mb-5">Bienvenue chez Loone, commençons ensemble 😊</h3>
        
          <div class="row justify-content-center mb-4">
            
            <!-- Bloc photo de profil -->
            <div class="col-12 col-sm-4 col-md-3 text-center mb-4">
              <label for="profil-logo" class="form-label">
 
                  <!-- Si la photo n'est pas encore importée, afficher le placeholder -->
                  <div *ngIf="!profileFormData.profilePhoto" id="logo-placeholder"
                  style="width: 85px; height: 85px; border: 1px dashed #ccc; cursor: pointer; border-radius: 50%;"
                  (click)="onLogoClick('profile-logo-input')" class="d-flex align-items-center justify-content-center mx-auto">
                  </div>
            
            <!-- Si la photo est présente, afficher l'image -->
            <div *ngIf="profileFormData.profilePhoto" id="logo-container"  class="d-flex align-items-center justify-content-center mx-auto"
                  style="width: 85px; height: 85px; border: 1px dashed #ccc; cursor: pointer; border-radius: 50%; position: relative;">
              <img [src]="profileFormData.profilePhoto" alt="Photo de profil"
                    style="width: 100%; height: 100%; border-radius: 50%; object-fit: cover;" (click)="onLogoClick('profile-logo-input')" />
              
              <!-- Bouton pour supprimer l'image -->
              <button (click)="resetLogo('profile')" style="position: absolute; top: -8px; right: -7px;">
                <i class="ri-close-line fs-10 text-danger"></i>
              </button>
            </div>

            <span class="d-block mt-2" style="cursor: pointer;" (click)="onLogoClick('profile-logo-input')" *ngIf="!profileFormData.profilePhoto">
              <span class="d-block">Importer ma photo de profil</span>
              <span class="d-block">(2 Mo maximum)</span>
            </span>
          
                
              </label>
              <!-- Input file caché pour choisir un fichier -->
              <input type="file" class="form-control d-none" id="profile-logo-input" (change)="onFileSelected($event, 'profile')" />
            </div>
        
            <!-- Bloc formulaire -->
            <div class="col-12 col-sm-8 col-md-6">
              <div class="form-group mb-3">
                <label class="mb-1">Quel est votre nom ? *</label>
                <input type="text" class="form-control inp-custom mx-inp" [(ngModel)]="profileFormData.lastName" placeholder="Ex: Dupont">
              </div>
        
              <div class="form-group mb-3">
                <label class="mb-1">Quel est votre prénom ?</label>
                <input type="text" class="form-control inp-custom mx-inp" [(ngModel)]="profileFormData.firstName" placeholder="Ex: Éric">
              </div>
        
              <div class="form-group mb-3">
                <label class="mb-1">Adresse postale *</label>
                <app-place-autocomplete
                  [(address)]="addressProfil.complete"
                  (placeChanged)="onPlaceChanged($event, 'profile')"
                  placeholder="Ex: 14 avenue Rolland Maurin, 13008 Marseille"
                  inputClass="inp-custom mx-inp">
                </app-place-autocomplete>
              </div>
        
              <div class="form-group mb-3">
                <label class="mb-1">Quel est votre numéro de téléphone ? *</label>
                <input type="text" class="form-control inp-custom mx-inp" [(ngModel)]="profileFormData.phone" placeholder="Ex: 0612345678"
                       (keypress)="allowOnlyNumbers($event)">
              </div>
            </div>
        
          </div>
        
          <div class="form-group mb-5">
            <p class="mb-2">Veuillez lire attentivement les <a href="assets/documents/conditions.pdf" target="_blank" rel="noopener noreferrer" style="color: #53a6d8;">conditions générales d'utilisation</a> en cliquant sur ce lien.</p>
        
            <!-- Case à cocher pour les conditions d'utilisation -->
            <div class="form-check mb-2">
              <input type="checkbox" class="form-check-input form-checked-secondary" id="termsConditionsCheckbox"
                     [(ngModel)]="profileFormData.termsConditionsAccepted">
              <label class="form-check-label" for="termsConditionsCheckbox" style="cursor: pointer;">
                J'accepte les conditions d'utilisation de Loone.
              </label>
            </div>
        
            <div class="form-check">
              <input type="checkbox" class="form-check-input form-checked-secondary" id="termsCheckbox"
                     [(ngModel)]="profileFormData.termsCommercialsAccepted">
              <label class="form-check-label" for="termsCheckbox" style="cursor: pointer;">
                Je confirme que je n'utiliserai Loone que pour mes activités commerciales, mon entreprise, mes créations ou ma profession.
              </label>
            </div>
          </div>
        </div>
        

        <!-- Step 2: Company Information -->
        <div *ngIf="currentStep === 1" class="mx-auto">
          <h3 class="mb-5">Ajoutez votre entreprise 💼</h3>
        
          <div class="row justify-content-center">
            
            <!-- Bloc du logo de l'entreprise -->
            <div class="col-12 col-sm-4 col-md-3 text-center mb-4">
              <label for="company-logo" class="form-label">
                <div *ngIf="!companyFormData.logo" id="logo-placeholder"
                     class="d-flex align-items-center justify-content-center mx-auto"
                     style="width: 150px; height: 100px; border: 1px dashed #ccc; cursor: pointer; color: white !important;"
                     (click)="onLogoClick('company-logo-input')">
                  <div class="mx-auto">
                    <span class="d-block">Importer mon logo</span>
                    <span class="d-block">(2 Mo maximum)</span>
                  </div>
                </div>
              
                <!-- Si le logo est présent, afficher l'image -->
                <div *ngIf="companyFormData.logo" id="logo-container" class="position-relative mx-auto pt-2"
                     style="max-width: 200px; max-height: 130px; border: 1px dashed #ccc; cursor: pointer;">
                  <img [src]="companyFormData.logo" alt="Logo de l'entreprise"
                       class="image-resize" style="max-width: 100%; max-height: 100%; object-fit: contain;"
                       (click)="onLogoClick('company-logo-input')" />
                  <button (click)="resetLogo('company')" style="position: absolute; top: 5px; right: 5px;">
                    <i class="ri-close-line fs-10 text-danger"></i>
                  </button>
                </div>
              </label>
        
              <!-- Input file caché pour choisir un fichier -->
              <input type="file" class="form-control d-none" id="company-logo-input" (change)="onFileSelected($event, 'company')" />
            </div>
        
            <!-- Bloc du formulaire -->
            <div class="col-12 col-sm-8 col-md-6">
              <div class="form-group mb-3">
                <label class="mb-1">Entrez votre SIRET/SIREN *</label>
                <input type="text" class="form-control inp-custom" [(ngModel)]="companyFormData.siret" 
                       placeholder="Ex: 98009090305" (input)="validateAndLoadEnterpriseInfo($event)" maxlength="14">
                <!-- Message d'erreur si la validation échoue -->
                <div *ngIf="siretError" class="text-info mt-2">{{ siretError }}</div>
              </div>
        
              <div class="form-group mb-3">
                <label class="mb-1">Quel est le nom de votre entreprise ? *</label>
                <input type="text" class="form-control inp-custom" [(ngModel)]="companyFormData.companyName" 
                       placeholder="Ex: Loone">
              </div>
        
              <div class="form-group mb-3">
                <label class="mb-1">Siège social *</label>
                <app-place-autocomplete
                  [(address)]="companyFormData.headOffice"
                  (placeChanged)="onPlaceChanged($event, 'company')"
                  placeholder="Ex: 14 avenue Rolland Maurin, 13008 Marseille"
                  inputClass="inp-custom mx-inp">
                </app-place-autocomplete>
              </div>
        
              <div class="form-group mb-3">
                <label class="mb-1">Code NAF</label>
                <input type="text" class="form-control inp-custom" [(ngModel)]="companyFormData.nafCode" 
                       placeholder="Ex: 99.99A">
              </div>
        
              <div class="form-group mb-3">
                <label class="mb-1">Numéro TVA Intracommunautaire</label>
                <input type="text" class="form-control inp-custom" [(ngModel)]="companyFormData.tvaNumber" 
                       placeholder="Ex: FR120..">
              </div>
        
              <div class="form-group mb-3">
                <label class="mb-1">Êtes-vous éligible à la TVA ?</label><br>
                <div class="form-check form-check-inline">
                  <input class="form-check-input form-checked-secondary" type="radio" id="vatEligibleYes" 
                         [(ngModel)]="companyFormData.vatEligible" [value]="true">
                  <label class="form-check-label" for="vatEligibleYes" style="cursor: pointer;">Éligible</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input form-checked-secondary" type="radio" id="vatEligibleNo" 
                         [(ngModel)]="companyFormData.vatEligible" [value]="false">
                  <label class="form-check-label" for="vatEligibleNo" style="cursor: pointer;">Non éligible</label>
                </div>
              </div>
        
            </div>
          </div>
        </div>
        

        <!-- Step 3: Accept Terms -->
        <div *ngIf="currentStep === 2" class="d-flex align-items-center" style="height: 30vh;">
          <div class="text-center mx-auto mt-5">
            <h3 class="mb-5">Félicitations ! Vous venez de configurer votre compte 🥳</h3>
            <button class="btn btn-primary ml-auto text-uppercase" (click)="redirect()" [disabled]="!isFormValid()">
              Continuer vers Loone
            </button>
          </div>
        </div>

        <div *ngIf="errorMessage" class="text-info">
          {{ errorMessage }}
        </div>

        <!-- Navigation Buttons -->
        <div class="d-flex justify-content-between mt-4" *ngIf="currentStep != 2">
          <button class="btn btn-outline-secondary" (click)="currentStep = currentStep - 1" *ngIf="currentStep > 0">Retour</button>
          <div class="d-block" *ngIf="currentStep == 0"></div>
          <button class="btn btn-primary ml-auto" (click)="nextStep()" [disabled]="!isFormValid() || isLoading">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
            <span *ngIf="!isLoading">
              {{ currentStep < totalSteps - 2 ? 'Étape suivante' : 'Terminer' }}
            </span>
          </button>
          
        </div>
      </div>
    </div>
  </div>
</div>
