import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const profile: Routes = [
  {
    path: 'profile', children: [{
      path: '',
      loadComponent: () =>
        import('./user.profile.component').then(
          (m) => m.UserProfileComponent
        ),
      title: 'LOONE - Profile'
    },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(profile)],
  exports: [RouterModule],
})
export class userProfilRoutingModule {
  static routes = profile;
}