import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { format, addMinutes, addHours, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ActivatedRoute } from '@angular/router';
import { CalendarService } from '../shared/services/calendar/calendar.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

const dayMapping: { [key: string]: string } = {
  Monday: 'lundi',
  Tuesday: 'mardi',
  Wednesday: 'mercredi',
  Thursday: 'jeudi',
  Friday: 'vendredi',
  Saturday: 'samedi',
  Sunday: 'dimanche'
};

@Component({
  selector: 'app-shared-calendar',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './shared-calendar.component.html',
  styleUrls: ['./shared-calendar.component.scss'],
})
export class SharedCalendarComponent implements OnInit {
  activityForm!: FormGroup;
  activities: any[] = [];
  availableDays: any[] = [];
  allowedDays: { [key: string]: boolean } = {};
  allowedTimeSlots: { start: string; end: string }[] = [];
  selectedDay: any = null;
  selectedTime: string | null = null;
  availableTimes: { label: string, available: boolean }[] = [];
  expandedTimeSlots: { label: string, available: boolean }[] = [];
  slotDuration: number = 30;
  unavailableSlots: any[] = [];
  clientFields: any = {};
  shareKey: string = '';
  invitationMessage: SafeHtml = '';
  loading: boolean = true;
  showConfirmationInput: boolean = false;
  activityId: string | null = null;
  confirmationCode: string = '';
  confirmationCodeSent: string = ''; 
  confirmed: boolean = false;
  appointmentSummary: { date: string; heure: string; duration: string } | null = null;
  currentWeekStart: Date = new Date();
  currentWeekRange: string = '';
  slotData: any; 
  clientData: any; 

  constructor(
    private calendarService: CalendarService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.currentWeekStart = this.getStartOfWeek(new Date());
    this.updateWeekRange();
    this.route.paramMap.subscribe(params => {
      this.shareKey = params.get('shareKey') || '';
      if (this.shareKey) {
        this.loadSharedCalendarSettings();
      } else {
        this.toastr.error('Clé de partage non valide');
      }
    });
  
    this.initActivityForm();
  }

  updateWeekRange(): void {
    const startOfWeek = this.currentWeekStart;
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    const options = { day: 'numeric', month: 'long' };
    this.currentWeekRange = `Semaine du ${format(startOfWeek, 'd MMMM', { locale: fr })} - ${format(endOfWeek, 'd MMMM', { locale: fr })}`;
  }

  loadSharedCalendarSettings(): void {
    this.loading = true;
    this.calendarService.getSharedCalendarSettings(this.shareKey).subscribe(
      (response) => {
        if (response.success) {
          const data = response.data;
          this.slotDuration = data.slotDuration;
          this.clientFields = data.clientFields || {};
          this.allowedTimeSlots = data.allowedTimeSlots || [];
          this.invitationMessage = this.sanitizer.bypassSecurityTrustHtml(data.invitationMessage || '');
          this.allowedDays = data.allowedDays || {}; 

          this.allowedTimeSlots = this.allowedTimeSlots.map(slot => {
            const startTime = new Date(`1970-01-01T${slot.start}:00`);
            const endTime = new Date(`1970-01-01T${slot.end}:00`);
            return {
              start: startTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }),
              end: endTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
            };
          });
  
          this.availableDays = this.configureAvailableDays(data.allowedDays);
          this.loading = false;
        } else {
          this.toastr.error('Erreur lors de la récupération des paramètres du calendrier partagé');
          this.loading = false;
        }
      },
      (error) => {
        this.toastr.error('Erreur lors de la récupération des paramètres du calendrier partagé');
        this.loading = false;
      }
    );
  }
  
  selectDay(day: any): void {
    this.selectedDay = day;
    this.selectedTime = null;
    this.expandedTimeSlots = [];
    this.loadUnavailableSlotsForDay(day.date).then(() => {
      this.availableTimes = this.getAvailableTimes(day.date);
    });
  }
  
  loadUnavailableSlotsForDay(date: Date): Promise<void> {
    return new Promise((resolve) => {
      this.calendarService.getUnavailableSlots(this.shareKey).subscribe(response => {
        if (response.success) {
          this.unavailableSlots = response.unavailableSlots.filter((slot: { date: string, start: string, end: string }) => {
            const slotDate = new Date(slot.date);
            return slotDate.toDateString() === date.toDateString();
          });
          resolve();
        } else {
          console.error('Error fetching unavailable slots:', response.message);
          this.toastr.error(response.message || 'Erreur lors du chargement des créneaux horaires indisponibles');
          resolve();
        }
      }, error => {
        console.error('Error during API call for unavailable slots:', error);
        this.toastr.error('Erreur lors du chargement des créneaux horaires indisponibles');
        resolve();
      });
    });
  }
  
  isTimeSlotAvailable(date: Date, time: string): boolean {
    const [hours, minutes] = time.split(':').map(Number);
    const startTime = new Date(date);
    startTime.setHours(hours, minutes, 0, 0);
  
    const endTime = addMinutes(startTime, this.slotDuration);
  
    const isAvailable = !this.unavailableSlots.some(slot => {
      const slotDate = new Date(slot.date);
      if (slotDate.toDateString() !== date.toDateString()) {
        return false;
      }
  
      const [slotStart, slotEnd] = [slot.start, slot.end].map(time => {
        const [h, m] = time.split(':').map(Number);
        const d = new Date(date);
        d.setHours(h, m, 0, 0);
        return d;
      });
  
      return (startTime < slotEnd && endTime > slotStart);
    });
    return isAvailable;
  }
  
  isWithinUnavailableSlots(start: Date, end: Date): boolean {
    return this.unavailableSlots.some(slot => {
      const slotStart = new Date(slot.start);
      const slotEnd = new Date(slot.end);
      const isOverlapping = start < slotEnd && end > slotStart;
      return isOverlapping;
    });
  }
  
  initActivityForm(): void {
    this.activityForm = this.fb.group({
      name: [''],
      firstName: [''],
      email: [''],
      phoneNumber: [''],
      commentary: [''],
      date: ['', Validators.required],
      heure: ['', Validators.required],
      duration: ['', Validators.required]
    });
  }

  getStartOfWeek(date: Date): Date {
    const day = date.getDay();
    const diff = (day === 0 ? -6 : 1) - day;
    return new Date(date.setDate(date.getDate() + diff));
  }

  navigateWeek(offset: number): void {
    const newDate = new Date(this.currentWeekStart);
    newDate.setDate(this.currentWeekStart.getDate() + offset * 7);
    this.currentWeekStart = newDate;
    this.updateWeekRange();
    this.availableDays = this.configureAvailableDays(this.allowedDays);
  }
  
  canNavigateToPreviousWeek(): boolean {
    return this.currentWeekStart > this.getStartOfWeek(new Date());
  }  

  configureAvailableDays(allowedDays: any): any[] {
    const startOfWeek = this.getStartOfWeek(this.currentWeekStart);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const days = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);

      const isPast = date < today;
      const dayName = format(date, 'EEEE', { locale: fr });
      const englishDayName = Object.keys(dayMapping).find((key) => dayMapping[key as keyof typeof dayMapping] === dayName.toLowerCase());

      if (!englishDayName) {
        console.error(`No matching English day name for ${dayName}`);
        return null;
      }

      return {
        date,
        label: format(date, 'EEEE d MMM', { locale: fr }),
        available: allowedDays[englishDayName] && !isPast,
        disabled: isPast
      };
    }).filter(day => day && day.available);
    
    return days;
  }

  selectTime(time: any): void {
    this.selectedTime = time.label;
    this.activityForm.patchValue({
      date: format(this.selectedDay.date, 'yyyy-MM-dd'),
      heure: this.selectedTime,
      duration: this.getDuration()
    });
  }  
  
  expandTimeSlots(time: any): void {
    if (!time.available) return;
  
    this.expandedTimeSlots = this.getTimeSlots(time.label);
  }

  getAvailableTimes(date: Date): { label: string, available: boolean }[] {
    if (!this.selectedDay || !this.clientFields || !this.allowedTimeSlots) return [];

    const timeSlots: { label: string, available: boolean }[] = [];

    this.allowedTimeSlots.forEach((slotRange: { start: string; end: string }) => {
        const [startHour, startMinute] = slotRange.start.split(':').map(Number);
        const [endHour, endMinute] = slotRange.end.split(':').map(Number);

        let currentHour = startHour;
        let currentMinute = 0;

        while (currentHour < endHour || (currentHour === endHour && currentMinute < endMinute)) {
            const slotLabel = `${String(currentHour).padStart(2, '0')}:${String(currentMinute).padStart(2, '0')}`;

            let allSubSlotsUnavailable = true;
            for (let i = 0; i < 4; i++) { 
                const subSlotTime = new Date(date);
                subSlotTime.setHours(currentHour, currentMinute + i * 15, 0, 0);
                const subSlotLabel = format(subSlotTime, 'HH:mm', { locale: fr });
                const available = this.isTimeSlotAvailable(date, subSlotLabel);

                if (available) {
                    allSubSlotsUnavailable = false;
                    break;
                }
            }

            timeSlots.push({
                label: slotLabel,
                available: !allSubSlotsUnavailable
            });

            currentHour += 1;
            currentMinute = 0;
        }
    });

    return timeSlots;
  }

  getTimeSlots(time: string): { label: string, available: boolean }[] {
    const baseTime = new Date(`1970-01-01T${time}:00`);
    const slots = [];
    for (let i = 0; i < 4; i++) {
        const slotTime = new Date(baseTime.getTime() + i * 15 * 60 * 1000);
        const localSlotTime = format(slotTime, 'HH:mm', { locale: fr });
        const available = this.isTimeSlotAvailable(this.selectedDay.date, localSlotTime);
        slots.push({
            label: localSlotTime,
            available
        });
    }
    return slots;
  }

  isHourAvailable(date: Date, hour: string): boolean {
    const hourStart = new Date(date);
    const [hourHours, hourMinutes] = hour.split(':').map(Number);
    hourStart.setHours(hourHours, hourMinutes, 0, 0);

    const hourEnd = addHours(hourStart, 1);
    const durationInMinutes = this.slotDuration;

    for (let i = 0; i <= 60 - durationInMinutes; i += 15) {
        const start = addMinutes(hourStart, i);
        const end = addMinutes(start, durationInMinutes);

        if (!this.isWithinUnavailableSlots(start, end)) {
            return true;
        }
    }
    return false;
  }

  resetSelection(): void {
    this.selectedDay = null;
    this.selectedTime = null;
    this.expandedTimeSlots = [];
    this.activityForm.reset();
  }

  getDuration(): string {
    const durationMinutes = this.slotDuration;
    const durationHours = Math.floor(durationMinutes / 60).toString().padStart(2, '0');
    const durationMins = (durationMinutes % 60).toString().padStart(2, '0');
    return `${durationHours}:${durationMins}`;
  }

  createActivity(): void {
    if (this.activityForm.invalid) {
      return;
    }
  
    const formData = this.activityForm.value;
    const localDateTime = new Date(`${formData.date}T${formData.heure}:00`);
    const utcDateTime = new Date(localDateTime.getTime() - (localDateTime.getTimezoneOffset() * 60000)).toISOString();
  
    this.slotData = {
      start: utcDateTime,
      duration: formData.duration
    };
  
    this.clientData = {
      name: formData.name,
      firstName: formData.firstName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      commentary: formData.commentary
    };
  
    const activityData = {
      shareKey: this.shareKey,
      slot: this.slotData,
      clientInfo: this.clientData
    };
    
    this.calendarService.bookSlot(activityData).subscribe(response => {
      if (response.success) {
        this.confirmationCodeSent = response.confirmationCode;
        this.showConfirmationInput = true;
        this.toastr.success('Code de confirmation envoyé. Veuillez entrer le code pour valider.');
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      console.error('Error during activity creation:', error);
      this.toastr.error('Erreur lors de la création de la réservation.');
    });
  }
  
  confirmBooking(): void {
    if (!this.slotData || !this.clientData || !this.confirmationCodeSent) {
      console.error('Required data is missing');
      this.toastr.error('Les informations de réservation sont manquantes.');
      return;
    }
  
    const confirmationData = {
      shareKey: this.shareKey,
      slot: this.slotData,
      clientInfo: this.clientData,
      confirmationCode: this.confirmationCode, 
      confirmationCodeSent: this.confirmationCodeSent 
    };
  
  
    this.calendarService.bookSlot(confirmationData).subscribe(response => {
      if (response.success) {
        this.toastr.success('Réservation confirmée et créée.');
        this.confirmed = true;
  
        this.appointmentSummary = {
          date: response.data.date,
          heure: response.data.heure,
          duration: response.data.duration
        };
      } else {
        this.toastr.error(response.message);
      }
    }, error => {
      console.error('Error during booking confirmation:', error);
      this.toastr.error('Erreur lors de la confirmation de la réservation.');
    });
  }  
}
