import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './shared/layouts/content-layout/content-layout.component';
import { content } from './shared/routes/content.routes';
import { AuthenticationLayoutComponent } from './shared/layouts/authentication-layout/authentication-layout.component';
import { authen } from './shared/routes/auth.routes';
import { PageheaderLayoutComponent } from './shared/layouts/pageheader-layout/pageheader-layout.component';
import { content1 } from './shared/routes/crm.routes';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin/admin.guard';
import { SharedCalendarComponent } from './shared-calendar/shared-calendar.component';
import { RevokedSharePageComponent } from './revoked-share-page/revoked-share-page.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingGuard } from './onboarding/onboarding.guard';


export const routes: Routes = [
    { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
    { 
        path: '', 
        component: ContentLayoutComponent, 
        canActivate: [AuthGuard], 
        children: content 
    },
    { 
        path: '', 
        component: PageheaderLayoutComponent, 
        canActivate: [AuthGuard], 
        children: content1 
    },
    { 
        path: '', 
        component: AuthenticationLayoutComponent, 
        children: authen 
    },
    {
        path: 'onboarding', 
        canActivate: [OnboardingGuard],
        component: OnboardingComponent
    },    
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AdminGuard]
    },
    { path: 'share/:shareKey', component: SharedCalendarComponent },
    { path: 'share-revoked', component: RevokedSharePageComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
