import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { UserService } from './shared/services/users/users.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private userService: UserService, private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    if (!this.authService.isUserEmailLoggedIn) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    return this.userService.getCurrentUser().pipe(
      map(user => {
        if (user && user.isActive) {
          return true; 
        } else {
          this.authService.logout(); 
          this.router.navigate(['/auth/login']); 
          return false;
        }
      }),
      catchError(error => {
        this.authService.logout(); 
        this.router.navigate(['/auth/login']);
        return of(false);
      })
    );
  }
}
