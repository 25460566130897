import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const admin: Routes = [
  {
    path: 'settings', children: [{
      path: '',
      loadComponent: () =>
        import('./settings.component').then(
          (m) => m.SettingsComponent
        ),
      title: 'LOONE - Paramétres'
    },
    {
      path: 'update/:id/modele',
      loadComponent: () =>
        import('./modeles/create-modele/create-modele.component').then(
          (m) => m.CreateModeleComponent
        ),
      title: 'LOONE - Modele'
    },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class settingsRoutingModule {
  static routes = admin;
}