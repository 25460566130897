import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const admin: Routes = [  
 {path:'authentication/reset-password',children:[ 
   {
     path: 'forgot-password',
     loadComponent: () =>
       import('./forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent),
     title: 'LOONE - Mot de passe oublié'
   },
   {
     path: 'reset-password',
     loadComponent: () =>
       import('./reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
     title: 'LOONE - Réinitialiser le mot de passe'
   },
 ]}
];

@NgModule({
  imports: [RouterModule.forChild(admin)],
  exports: [RouterModule],
})
export class resetpasswordRoutingModule {
  static routes = admin;
}
