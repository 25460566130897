      import { NgModule } from '@angular/core';
      import { RouterModule, Routes } from '@angular/router';
      
      export const admin: Routes = [
       {path:'facturation',children:[{
        path: '',
        loadComponent: () =>
          import('../components/facturation/facturation.component').then(
            (m) => m.FacturationComponent
          ),
          title: 'LOONE - Facturation'
      },{
        path: 'create/invoice',
        loadComponent: () =>
          import('../components/facturation/invoice/create-invoice/create-invoice.component').then((m) => m.CreateInvoiceComponent),
          title: 'LOONE - Invoice'
      },
      {
        path: 'create/:id/invoice',
        loadComponent: () =>
          import('../components/facturation/invoice/create-invoice/create-invoice.component').then(
            (m) => m.CreateInvoiceComponent
          ),
        title: 'LOONE - Invoice Details'
      },
      {
        path: ':id/invoice',
        loadComponent: () =>
          import('../components/facturation/invoice/view-invoice/view-invoice.component').then(
            (m) => m.ViewInvoiceComponent
          ),
        title: 'LOONE - Invoice Details'
      },
      {
        path: 'create/quote',
        loadComponent: () =>
          import('../components/facturation/quote/create-quote/create-quote.component').then((m) => m.CreateQuoteComponent),
          title: 'LOONE - Quote'
      },
      {
        path: 'create/:id/quote',
        loadComponent: () =>
          import('../components/facturation/quote/create-quote/create-quote.component').then(
            (m) => m.CreateQuoteComponent
          ),
        title: 'LOONE - Quote Details'
      },
      {
        path: ':id/quote',
        loadComponent: () =>
          import('../components/facturation/quote/view-quote/view-quote.component').then(
            (m) => m.ViewQuoteComponent
          ),
        title: 'LOONE - Quote Details'
      },
      ]}
      ];
      @NgModule({
        imports: [RouterModule.forChild(admin)],
        exports: [RouterModule],
      })
      export class invoiceRoutingModule {
        static routes = admin;
      }
