import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const chatPage: Routes = [
  {
    path: 'chat-page', children: [{
      path: '',
      loadComponent: () =>
        import('./chat-page.component').then(
          (m) => m.ChatPageComponent
        ),
      title: 'LOONE - Assistant'
    },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(chatPage)],
  exports: [RouterModule],
})
export class chatPageRoutingModule {
  static routes = chatPage;
}